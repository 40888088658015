<template>
  <div class="dialogBox addToCartBox d-flex flex-column align-center" style="width: 100%">
    <v-icon color="secondary" size="80">mdi-check-circle-outline</v-icon>
    <h4 class="ma-3" style="color: f5f5f5">{{ $t("dialogMsg.payment.premium.title") }}</h4>
    <table style="width: 100%; padding-bottom: 20px">
      <tr v-if="paymentInfo.paymentType === 2">
        <td>{{ $t("paymentBuyer") }}</td>
        <td style="text-align: right">{{ dealInfo.buyer }}</td>
      </tr>
      <tr v-if="paymentInfo.paymentType === 2">
        <td>{{ $t("paymentSeller") }}</td>
        <td style="text-align: right">{{ dealInfo.seller }}</td>
      </tr>
      <tr v-if="paymentInfo.paymentType === 2">
        <td>{{ $t("chatContractType") }}</td>
        <td style="text-align: right">
          {{ dealInfo.orderType === 0 ? $t("chatroomChatTypeTransfer") : $t("chatroomChatTypeLicense") }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("fpsAmount") }}</td>
        <td style="text-align: right">{{ currency.concat(price) }}</td>
      </tr>
      <tr v-if="paymentInfo.paymentType === 2 && dealInfo.orderType === 0">
        <td>{{ $t("chatContractDateStart") }}</td>
        <td style="text-align: right">
          {{
            dealInfo.orderType === 0
              ? formatDate(dealInfo.effectiveDate)
              : formatDate(dealInfo.effectiveDate).concat(" -- ").concat(formatDate(dealInfo.expiryDate))
          }}
        </td>
      </tr>

      <tr>
        <td>{{ $t("paymentReferenceNumber") }}</td>
        <td style="text-align: right">{{ this.txnRef }}</td>
      </tr>
    </table>
    <p v-if="paymentInfo.paymentType === 0">{{ $t("dialogMsg.payment.premium.message") }}</p>

    <v-btn
      v-if="paymentInfo.paymentType === 0"
      color="primary"
      depressed
      dark
      rounded
      x-large
      :class="messageClass"
      @click="logout"
    >
      <h3>{{ $t("dialogButton.logout") }}</h3>
    </v-btn>
    <v-btn
      v-if="paymentInfo.paymentType === 2"
      color="primary"
      depressed
      dark
      rounded
      x-large
      :class="messageClass"
      @click="$emit('paymentComplete')"
    >
      <h3>{{ $t("dialogButton.confirm") }}</h3>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    paymentInfo: {
      type: Object
    },
    dealInfo: {
      type: Object
    },
    txnRef: {
      type: String,
      default: ""
    },
    currency: {
      type: String,
      default: "USD"
    },
    price: {}
  },
  methods: {
    formatDate(date) {
      function isValidDate(d) {
        return new Date(d) instanceof Date && !isNaN(d);
      }
      if (isValidDate(date)) {
        return new Date(date).toISOString().substr(0, 10);
      } else {
        return "";
      }
    },
    logout: function () {
      this.$store.dispatch("clearImageURL");
      this.$session.destroy();
      this.$router.push("/login").catch((e) => e);
      this.$store.commit("addr", null);
      this.$store.commit("changeState", false);
      this.$store.commit("refreshUserProfile", {});

      this.$store.dispatch("resetState");
    }
  },
  data: () => ({
    messageClass: "message"
  })
};
</script>

<style></style>
