<template>
  <div class="premiumUpgrade">
    <message-box
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <v-dialog v-model="paymentBox" max-width="600px" persistent>
      <premium-payment @close="closePayment" :paymentInfo="paymentInfo" @paymentComplete="paymentComplete" />
    </v-dialog>
    <v-dialog v-model="paymentBoxFPS" max-width="600px" persistent>
      <PaymentFPS
        @close="paymentBoxFPS = false"
        :paymentInfo="paymentInfo"
        :paymentBoxOpen="paymentBoxFPS"
        @paymentComplete="paymentComplete"
      />
    </v-dialog>
    <v-dialog v-model="paymentBoxStripe" max-width="600px" persistent>
      <payment-stripe
        @close="paymentBoxStripe = false"
        :paymentInfo="paymentInfo"
        :paymentBoxOpen="paymentBoxStripe"
        @paymentComplete="paymentComplete"
      />
    </v-dialog>
    <v-dialog v-model="paymentSuccessOpen" max-width="400px">
      <payment-success :paymentInfo="paymentInfo" :txnRef="txnRef" :currency="currency" :price="price" />
    </v-dialog>
    <SignupUI>
      <template v-slot:stepper>
        <div class="premiumText" v-if="progress === 1 && !loading">
          <div class="flex-grow-1" style="width: 100%">
            <h2>{{ $t("premiumHeading", { Username: userid }) }}</h2>
            <h5>{{ $t("premiumSubheading") }}</h5>
            <p class="mb-5" v-if="paymentAllowed">{{ $t("premiumText.header", { price }) }}</p>
            <div class="list-item">
              <img src="@/assets/zeplin/tick-mark.svg" class="mt-0" />
              {{ $t("premiumText.timestamp") }}
            </div>
            <div class="list-item">
              <img src="@/assets/zeplin/tick-mark.svg" class="mt-0" />
              {{ $t("premiumText.immutable") }}
            </div>
            <div class="list-item">
              <img src="@/assets/zeplin/tick-mark.svg" class="mt-0" />
              {{
                $t("premiumText.storage", {
                  numberOfWorks: paymentClassChoices[paymentInfo.paymentClass - 1].numberOfWorks,
                  storageQuota: paymentClassChoices[paymentInfo.paymentClass - 1].storageQuota
                })
              }}
            </div>
            <!--<div class="list-item">
              <img src="@/assets/zeplin/tick-mark.svg" class="mt-0" />
              {{ $t("premiumText.sell") }}
            </div>-->
            <div class="list-item">
              <img src="@/assets/zeplin/tick-mark.svg" class="mt-0" />
              {{ $t("premiumText.otherFeatures") }}
            </div>

            <v-select
              outlined
              dense
              hide-details=""
              v-model="paymentInfo.paymentDurationMonth"
              label="Duration"
              required
              :items="paymentDurationMonthChoices"
              item-text="label"
              class="my-5"
              @change="checkPrice"
            ></v-select>
            <v-select
              outlined
              dense
              hide-details=""
              v-model="paymentInfo.paymentClass"
              label="Class"
              required
              :items="paymentClassChoices"
              item-text="label"
              class="my-5"
              @change="checkPrice"
              v-if="env !== 'production'"
            ></v-select>
          </div>

          <div class="paymentButtonGroup flex-grow-1" v-if="paymentAllowed">
            <v-btn id="paypal-btn" @click="payForPremium" :ripple="false" v-if="enablePaypal">
              <img
                src="@/assets/zeplin/1280-px-pay-pal-svg.png"
                srcset="@/assets/zeplin/1280-px-pay-pal-svg@2x.png, @/assets/zeplin/1280-px-pay-pal-svg@3x.png"
                class="px-PayPalsvg"
              />
            </v-btn>
            <v-btn id="fps" :ripple="false" @click="paymentBoxFPS = true" v-if="enableFPS">
              <img
                src="@/assets/zeplin/fps.png"
                srcset="@/assets/zeplin/fps@2x.png, @/assets/zeplin/fps@3x.png"
                class="fps"
              />
            </v-btn>
            <v-btn id="stripe" :ripple="false" @click="paymentBoxStripe = true" v-if="enableStripe">
              <img src="@/assets/payment/visa.svg" alt="Visa" style="max-width: 40px; height: auto" />
              <img src="@/assets/payment/mc_symbol.svg" alt="Mastercard" style="max-width: 40px; height: auto" />
            </v-btn>
          </div>
          <div class="flex-grow-1" style="width: 100%" v-else>
            <v-alert type="error" dense text style="width: 100%">{{ $t("errorMsg.premiumMember.notAllowed") }}</v-alert>
          </div>
          <div class="skipButton">
            <v-btn rounded color="primary" x-large="" depressed @click="skip" data-cy="premiumSkip"
              ><span style="font-weight: 600; text-transform: uppercase">{{ $t("premiumSkip") }} </span></v-btn
            >
          </div>
        </div>
        <div style="width: 100%; display: flex; align-items: center; justify-content: center" v-else>
          <v-progress-circular indeterminate size="50" color="primary" />
        </div>
      </template>
    </SignupUI>
  </div>
</template>

<script>
import SignupUI from "@/components/Signup/SignupUI";
import PlatformLayout from "@/layouts/Platform";
import { checkPrice } from "../../services/authService";
import config from "../../config";
import PremiumPayment from "./PremiumPayment.vue";
import PaymentFPS from "./PaymentFPS.vue";
import PaymentSuccess from "./PaymentSuccess.vue";
import PaymentStripe from "./PaymentStripe.vue";
import MessageBox from "@/components/Common/MessageBox.vue";
import { getStripePaymentIntent, payForService } from "../../services/orderService";
export default {
  name: "Premium",
  components: {
    SignupUI,
    PremiumPayment,
    PaymentFPS,
    PaymentSuccess,
    PaymentStripe,
    MessageBox
  },
  data: () => ({
    price: 60,
    paymentBox: false,
    paymentBoxFPS: false,
    paymentBoxStripe: false,
    progress: 1,
    paymentAllowed: true,
    paymentSuccessOpen: false,
    stripeChecked: false,
    txnRef: null,
    currency: "USD",
    errorMsg: "",
    loading: false,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },

    paymentClassChoices: [
      { label: "Class 1 (10GB)", value: 1, numberOfWorks: "3,000", storageQuota: "10GB" },
      { label: "Class 2 (30GB)", value: 2, numberOfWorks: "9,000", storageQuota: "30GB" },
      { label: "Class 3 (50GB)", value: 3, numberOfWorks: "15,000", storageQuota: "50GB" },
      { label: "Class 4 (1TB)", value: 4, numberOfWorks: "300,000", storageQuota: "1TB" }
    ],
    paymentInfo: {
      paymentType: 0,
      paymentDurationMonth: 12,
      paymentClass: 1
    }
  }),
  async mounted() {
    this.$emit(`update:layout`, PlatformLayout);
    this.loading = true;
    await this.checkStripeComplete();
    await this.checkPrice();
    this.stripeChecked = true;
    this.loading = false;
  },
  computed: {
    userid() {
      return this.$store.state.userProfile.userid;
    },

    env() {
      return process.env.NODE_ENV;
    },
    paymentInfoString() {
      return {
        paymentType: `${this.paymentInfo.paymentType}`,
        paymentClass: `${this.paymentInfo.paymentClass}`,
        paymentDurationMonth: `${this.paymentInfo.paymentDurationMonth}`
      };
    },
    enableFPS() {
      return config.fps;
    },
    enablePaypal() {
      return config.paypal;
    },
    enableStripe() {
      return config.stripe;
    },
    paymentDurationMonthChoices() {
      let choices = [
        { label: "12 months", value: 12 },
        { label: "24 months", value: 24 },
        { label: "36 months", value: 36 }
      ];
      return choices.map((elem) => {
        elem.label = this.$t("premiumMonths", { duration: elem.value });
        return elem;
      });
    }
  },
  methods: {
    skip() {
      this.$router.go(-1);
    },
    payForPremium() {
      this.paymentBox = true;
    },
    closePayment() {
      this.paymentBox = false;
    },
    nextStep() {
      this.progress = this.progress + 1;
    },

    prevStep() {
      this.progress = this.progress - 1;
    },
    async checkPrice() {
      this.$store.commit("showProgressLine", true);
      const payload = {
        paymentType: 0,
        paymentClass: this.paymentInfo.paymentClass,
        paymentDurationMonth: this.paymentInfo.paymentDurationMonth
      };
      const token = this.$session.get("token");
      const result = await checkPrice(this.$axios, config, token, payload);
      if (result.status === 200) {
        this.price = result.data.price;
        this.paymentAllowed = true;
      } else if (result.status === 400) {
        this.errorMsg = this.$t("errorMsg.premiumMember.notAllowed");
        this.paymentAllowed = false;
      } else {
        this.errorMsg = this.$t("dialogMsg.myCollection.blockchainFail");
        this.paymentAllowed = false;
      }
      this.$store.commit("showProgressLine", false);
    },
    paymentComplete(info) {
      this.paymentSuccessOpen = true;
      this.paymentBoxFPS = false;
      this.paymentBoxStripe = false;
      this.paymentBox = false;
      this.txnRef = info.txnRef;
      if (info.price) {
        this.currency = info.currency;
        this.price = info.price;
      }
    },
    async payForServiceStripe(intentid) {
      this.stripeChecked = true;
      let paymentInfo = localStorage.getItem("paymentInfo");
      if (paymentInfo) {
        const payload = JSON.parse(paymentInfo);
        payload.intentid = intentid;
        const token = this.$session.get("token");
        const payForServiceResponse = await payForService(this.$axios, config, token, payload);
        return payForServiceResponse;
      } else {
        return;
      }
    },
    async checkStripeComplete() {
      if (this.stripeChecked) {
        return;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const paymentIntentId = urlParams.get("payment_intent");
      if (urlParams.get("redirect_status") === "succeeded" && !!paymentIntentId) {
        const token = this.$session.get("token");
        const paymentDetails = await getStripePaymentIntent(this.$axios, config, token, paymentIntentId);

        if (paymentDetails.status === 200) {
          const paymentStatus = paymentDetails.data.data.status;
          if (paymentStatus === "succeeded") {
            const payForServiceResponse = await this.payForServiceStripe(paymentIntentId);
            const alreadyProcessed =
              payForServiceResponse.status === 400 && payForServiceResponse.data.message === "already processed";
            if (payForServiceResponse.status === 200 || alreadyProcessed) {
              const txnRef = paymentIntentId;
              const price = paymentDetails.amount_received;
              const currency = paymentDetails.currency;
              const info = { txnRef, price, currency };
              this.paymentComplete(info);
            }
          } else {
            this.messageBox.content = paymentDetails.statusText;
            this.messageBox.state = true;
          }
        }
      }
    }
  }
};
</script>
