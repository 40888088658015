<template>
  <div>
    <v-btn
      v-if="!buyerSigningCheckFail"
      :loading="buyerSigningLoading"
      dense
      id="verifyPaymentButton"
      rounded
      depressed
      @click="checkPaymentWithSigning"
      style="width: auto"
    >
      <v-icon small>mdi-credit-card-outline</v-icon>
      {{ $t("fpsCheckPayment") }}
    </v-btn>
    <v-btn v-else dense color="#FF5252" rounded depressed dark style="width: auto" :ripple="false">
      <v-icon small>mdi-alert-circle-outline</v-icon>
      {{ $t("fpsCheckFail") }}
    </v-btn>
  </div>
</template>

<script>
import config from "../../config";
import { saveChatMessage } from "../../services/chatService";
import { signByBuyer } from "../../services/orderService";
export default {
  data: () => ({
    buyerSigningLoading: false,
    buyerSigningCheckFail: false
  }),
  props: {
    orderid: {
      required: true,
      type: String
    }
  },
  methods: {
    async checkPaymentWithSigning() {
      this.buyerSigningLoading = true;
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const result = await signByBuyer(this.$axios, config, token, this.orderid, keypair);
      if (result.status === 200) {
        let message = {
          class: "buyerSign"
        };
        saveChatMessage(this.$axios, config, token, this.orderid, JSON.stringify(message)).then(() => {
          this.$emit("buyerManualSign");
        });
      } else {
        this.buyerSigningCheckFail = true;
        setTimeout(() => {
          this.buyerSigningCheckFail = false;
        }, 5000);
      }
      this.buyerSigningLoading = false;
    }
  }
};
</script>

<style></style>
