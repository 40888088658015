<template>
  <div></div>
</template>

<script>
export default {
  created() {
    window.location.href = "https://www.youtube.com/playlist?list=PL791ItRma1LuPI8S51gvwtgT9yMyUGKje";
  }
};
</script>

<style></style>
