<template>
  <div id="maintenance">
    <title>Site Maintenance</title>

    <h1>Tectechain will be back soon!</h1>

    <p>
      Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. Feel free to
      <a href="mailto:standbyu@tectechain.com">contact us</a> if you need any help.
    </p>
    <p>&mdash; Tectechain</p>
    <br />

    <h1>其跡即將重新上線！</h1>
    <p>不好意思，我們現正進行一些系統修復。如有需要，請<a href="mailto:standbyu@tectechain.com">聯絡</a>我們。</p>
    <p>&mdash; 其跡</p>
    <br />
    <v-overlay v-if="systemNormal" opacity="0.8">
      <div id="maintenance-overlay">
        <v-progress-circular size="60" :value="timerCountdown"></v-progress-circular>
        <h2>
          其跡現在正常運作，請按<a
            style="color: white; text-decoration: underline; font-weight: 700"
            :href="mainPageLink"
            >這裡</a
          >進入主頁
          <br />
          Tectechain is operating normally. Please press
          <a style="color: white; text-decoration: underline; font-weight: 700" :href="mainPageLink">here</a> to visit
          Tectechain.
        </h2>
        <br />
        <p>你將會在五秒之內被定向至其跡主頁<br />You will be redirected to the Tectechain homepage in 5 seconds</p>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import config from "../config";
import Blank from "../layouts/Blank";
export default {
  data: () => ({
    systemNormal: false,
    timerCountdown: 0
  }),
  created() {
    this.$emit(`update:layout`, Blank);
    this.$axios.get(`${config.frontend}/static/config.json`, {}).then((res) => {
      if (!res.data.maintenance) {
        this.systemNormal = true;

        const timerInterval = setInterval(() => {
          this.timerCountdown = this.timerCountdown + 20;
        }, 900);
        setTimeout(() => {
          this.$router.push("/").catch(() => {});
          clearInterval(timerInterval);
        }, 5000);
      }
    });
  },
  computed: {
    mainPageLink() {
      return config.frontend;
    }
  }
};
</script>

<style></style>
