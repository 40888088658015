export async function createNewOrder(axios, config, token, workid) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const payload = {
      workid
    };

    const result = await axios.post(`${config.backend_api}/orders`, payload, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function getChatRecord(axios, config, token, orderid) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };

    const result = await axios.get(`${config.backend_api}/chats/${orderid}`, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function saveChatMessage(axios, config, token, orderid, message) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    const payload = {
      message
    };

    const result = await axios.post(`${config.backend_api}/chats/${orderid}`, payload, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function readChatRecords(axios, config, token, orderid) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    const result = await axios.get(`${config.backend_api}/chats/${orderid}`, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function downloadPdfBackend(axios, config, token, orderid, doc, lang) {
  try {
    const opt = {
      method: "post",
      url: `${config.contract_api}/${doc}/${orderid}`,
      headers: {
        Authorization: token
      },
      data: {
        lang: lang
      },
      responseType: "blob"
    };
    const result = axios(opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}
