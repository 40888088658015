<template>
  <div
    class="dialogBox addToCartBox px-0 py-0 d-flex flex-column align-center"
    style="overflow: hidden; display: flex; flex-direction: column; align-items: center"
  >
    <div style="width: 100%; display: flex; flex-direction: row-reverse" v-if="!paid">
      <v-btn icon width="30px" height="30px" class="ma-3" @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <v-progress-circular indeterminate v-if="!loaded" size="50" color="primary" class="ma-6" />
    <div
      v-if="loaded && !paid"
      class="px-6 py-0"
      style="width: 100%; padding: 12px; display: flex; align-items: center; flex-direction: column; overflow: hidden"
    >
      <div class="fpsInfoQR">
        <div style="display: flex; align-items: center; flex-direction: column">
          <h2>{{ $t("fpsDoNotClose") }}</h2>
          <h1 style="">{{ $t("fpsAmount") }} {{ currency.concat(price) }}</h1>
        </div>
        <div id="content">
          <div style="flex: 1">
            <!-- <vue-qrcode
              :value="paymentDetails.qrCode"
              style="width: 100%; max-width: 500px"
              :quality="1"
              :options="{ color: { dark: '#0074d9', light: '#7fdbff' } }"
            /> -->
            <div style="width: 100%; display: flex; align-items: center; flex-direction: column">
              <v-img :src="qrCodeImage" style="margin: 10px 0px 0px 0px" max-width="70vw">
                <v-fade-transition>
                  <v-overlay :absolute="true" v-if="paymentChecking" color="#ffffff" opacity="0.9">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      style="z-index: 50"
                      indeterminate
                    ></v-progress-circular>
                  </v-overlay>
                </v-fade-transition>
              </v-img>
            </div>
            <div v-if="development">TxnRef: {{ this.txnRef }} (dev use only)</div>
            <div style="width: 100%; display: flex; align-items: center; flex-direction: column">
              <div id="logoBox">
                <img
                  src="@/assets/zeplin/fps.png"
                  srcset="@/assets/zeplin/fps@2x.png, @/assets/zeplin/fps@3x.png"
                  style="height: 40px"
                />
                <img src="@/assets/figma/hsbc.png" style="height: 30px" />
              </div>
            </div>
            <div>
              <h2 style="padding: 0">{{ $t("fpsTimeLimit") }}</h2>
            </div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; align-items: center">
            <div
              style="display: flex; justify-content: space-around; width: 100%; margin: 20px 0 20px 0"
              class="hidden-sm-and-up"
            >
              <div
                style="display: flex; align-items: center; flex-direction: column; cursor: pointer"
                class="mx-2"
                @click="downloadQRImage"
              >
                <v-icon>mdi-download</v-icon>
                <span>{{ $t("fpsDownloadQRCode") }}</span>
              </div>
              <div
                style="display: flex; align-items: center; flex-direction: column; cursor: pointer"
                class="mx-2"
                @click="displayInstructions = !displayInstructions"
              >
                <v-icon>mdi-help-circle-outline</v-icon>
                <span>{{ $t("fpsInstructions") }}</span>
              </div>
            </div>

            <div style="flex: 1" :class="displayInstructions ? '' : 'hidden-xs-only'" id="instructions">
              <ol>
                <li>{{ $t("fpsStep1") }}</li>
                <li>{{ $t("fpsStep2") }}</li>
                <h5 class="sub">{{ $t("fpsStep2Additional") }}</h5>
                <li>{{ $t("fpsStep3") }}</li>
                <li v-if="paymentInfo.paymentType === 2">{{ $t("fpsStep4") }}</li>
              </ol>
              <div class="my-2" style="display: flex; justify-content: center" v-if="paymentInfo.paymentType === 2">
                <check-payment-manual :orderid="this.orderid" />
              </div>
            </div>

            <div id="desktopQRDownload">
              <v-btn depressed color="primary" rounded min-width="200px" @click="downloadQRImage">
                <v-icon>mdi-download</v-icon>{{ $t("fpsDownloadQRCode") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="messageBox.state">
      <v-alert type="error" text>{{ messageBox.content }}</v-alert>
    </div>
  </div>
</template>

<script>
import PlatformLayout from "@/layouts/Platform";
import config from "@/config";
// import VueQrcode from "vue-qrcode";
import { payForService, signByBuyer } from "../../services/orderService";

import { callbackFPS, createPaymentFPS } from "../../services/transactionService";
import QRCode from "qrcode";
import { saveChatMessage } from "../../services/chatService";
import CheckPaymentManual from "../../components/Common/CheckPaymentManual.vue";

// With async/await

export default {
  components: { CheckPaymentManual },
  name: "PaymentFPS",
  data: () => ({
    loaded: false,
    paymentChecking: false,
    countInterval: null,
    paid: false,
    paypalResult: {},
    paymentDetails: null,
    error: false,
    displayInstructions: false,
    callbackSuccessful: false,
    messageBox: {
      state: false,
      title: "",
      content: "",
      cssClass: "error",
      destination: ""
    },
    txnRef: null,
    qrCodeImage: null,
    messageDisplay: null,
    messageClass: "message",
    paymentDurationMonthChoices: [
      { label: "12 months", value: 12 },
      { label: "24 months", value: 24 },
      { label: "36 months", value: 36 }
    ],
    paymentClassChoices: [
      { label: "Class 1 (10GB)", value: 1, numberOfWorks: "3,000", storageQuota: "10GB" },
      { label: "Class 2 (30GB)", value: 2, numberOfWorks: "9,000", storageQuota: "30GB" },
      { label: "Class 3 (50GB)", value: 3, numberOfWorks: "15,000", storageQuota: "50GB" },
      { label: "Class 4 (1TB)", value: 4, numberOfWorks: "300,000", storageQuota: "1TB" }
    ],
    connection: null,
    buyerSigningLoading: false,
    buyerSigningCheckFail: false
  }),
  props: {
    orderid: {
      type: String,
      required: false,
      default: ""
    },
    paymentInfo: {
      type: Object,
      required: true
    },
    paymentBoxOpen: {
      type: Boolean
    },
    dealInfo: {
      type: Object
    }
  },
  async mounted() {
    this.$emit(`update:layout`, PlatformLayout);

    this.$store.commit("showProgressLine", true);
    await this.initialise();
    this.countInterval = setInterval(() => {
      this.checkPayment();
    }, 5000);

    this.$store.commit("showProgressLine", false);
  },
  beforeDestroy() {
    this.connection.onclose = () => {};
    const payload = JSON.stringify({ action: 2 });
    this.connection.send(payload);
    clearInterval(this.countInterval);
  },
  computed: {
    userid() {
      return this.$store.state.userProfile.userid;
    },
    publicPath() {
      return process.env.BASE_URL;
    },

    price() {
      let amount;
      if (this.paymentDetails.amount) {
        amount = parseInt(this.paymentDetails.amount) / 100;
      } else {
        amount = parseInt(this.paymentDetails.price) / 100;
      }

      return amount.toFixed(2);
    },
    currency() {
      if (this.paymentDetails.currency) {
        return this.paymentDetails.currency;
      } else {
        return "HKD";
      }
    },
    development() {
      return process.env.VUE_APP_ENV === "staging";
    }
  },
  methods: {
    async initialise() {
      this.loaded = false;
      const result = await createPaymentFPS(this.$axios, config, this.paymentInfo, this.$session.get("token"));
      if (result.status === 200) {
        this.paymentDetails = result.data.data;
        this.txnRef = result.data.data.txnRef;
        this.qrCodeImage = await this.generateQR(result.data.data.qrCode);
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
      }
      this.initConnection();
      this.loaded = true;
    },
    close() {
      this.error = false;
      clearInterval(this.countInterval);
      this.$emit("close");
    },
    paymentSuccess() {
      this.$emit("paymentSuccess", { txnRef: this.txnRef, currency: this.currency, price: this.price });
    },
    formatDate(date) {
      function isValidDate(d) {
        return new Date(d) instanceof Date && !isNaN(d);
      }
      if (isValidDate(date)) {
        return new Date(date).toISOString().substr(0, 10);
      } else {
        return "";
      }
    },
    async payForService() {
      this.paymentChecking = true;
      const token = this.$session.get("token");
      let paymentInfo;
      if (this.paymentInfo.paymentType === 0) {
        paymentInfo = {
          paymentType: this.paymentInfo.paymentType,
          paymentClass: this.paymentInfo.paymentClass,
          paymentDurationMonth: this.paymentInfo.paymentDurationMonth,
          txnRef: `${this.txnRef}`
        };
        const result = await payForService(this.$axios, config, token, paymentInfo);
        if (result.status === 500) {
          this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
          this.messageBox.state = true;
          this.messageBox.cssClass = "message";
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        } else if (result.status !== 200) {
          this.error = `Payment cannot be completed. ${result.data.message}`;
          this.paymentChecking = false;
          return;
        }
        if (this.paymentInfo.paymentType === 0) {
          this.$session.set("token", result.data.data);
        }
      }
      this.paymentChecking = false;
      this.$emit("paymentComplete", { txnRef: this.txnRef, currency: this.currency, price: this.price });
    },
    logout: function () {
      this.$store.dispatch("clearImageURL");
      this.$session.destroy();
      this.$router.push("/login").catch((e) => e);
      this.$store.commit("addr", null);
      this.$store.commit("changeState", false);
      this.$store.commit("refreshUserProfile", {});

      this.$store.dispatch("resetState");
    },
    async checkPayment() {
      let paymentInfo = {
        paymentType: this.paymentInfo.paymentType,
        txnRef: `${this.txnRef}`
      };
      if (this.paymentInfo.paymentType === 2) {
        paymentInfo.orderid = this.orderid;
      }
      const result = await callbackFPS(this.$axios, config, paymentInfo, this.$session.get("token"));
      if (result.status === 200 || (result.status === 400 && result.data.message === "Multiple Transactions")) {
        clearInterval(this.countInterval);
        if (this.paymentInfo.paymentType === 0) {
          this.payForService();
        } else {
          this.$emit("paymentComplete", { txnRef: this.txnRef, currency: this.currency, price: this.price });
        }
      }
    },
    async generateQR(text) {
      try {
        let result = await QRCode.toDataURL(text, {
          errorCorrectionLevel: "H",
          type: "image/jpeg",
          quality: 1,
          margin: 0,
          color: {
            dark: "#000000",
            light: "#FFFFFF"
          },
          width: 300,
          height: 300
        });
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    downloadQRImage() {
      var a = document.createElement("a"); //Create <a>
      a.href = this.qrCodeImage; //Image Base64 Goes here
      a.download = "qrcode.jpeg"; //File name Here
      a.click(); //Downloaded file
    },
    initConnection() {
      this.connection = new WebSocket(`${config.ws_api}/message/notification`);

      this.connection.onopen = () => {
        const token = this.$session.get("token");
        const payload = { action: 0, token: token };
        this.sendConnection(JSON.stringify(payload));
      };

      this.connection.onmessage = (event) => {
        const token = this.$session.get("token");
        if (!event.data) return;

        const msg = JSON.parse(event.data);

        switch (msg.type) {
          case 0:
            if (msg.data[0].notificationType === 25 && msg.data[0].txnRef === this.txnRef) {
              // this.checkPayment();
            }
            // this.$store.dispatch("pushNotification", msg.data);
            break;
          case 1:
            if (msg.data[0].notificationType === 25 && msg.data[0].txnRef === this.txnRef) {
              // this.checkPayment();
            }
            // this.$store.dispatch("pushNotification", this.$store.state.notifications.concat(msg.data));
            break;
          case 2:
            this.sendConnection(JSON.stringify({ action: 0, token: token }));
            break;
        }
      };

      this.connection.onclose = () => {
        this.initConnection();
      };
    },
    sendConnection(payload) {
      this.connection.send(payload);
    },
    async checkPaymentWithSigning() {
      clearInterval(this.countInterval);
      this.buyerSigningLoading = true;
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const result = await signByBuyer(this.$axios, config, token, this.orderid, keypair);
      if (result.status === 200) {
        let message = {
          class: "buyerSign"
        };
        saveChatMessage(this.$axios, config, token, this.orderid, JSON.stringify(message)).then(() => {
          this.$emit("buyerManualSign");
        });
      } else {
        this.buyerSigningCheckFail = true;
        setTimeout(() => {
          this.buyerSigningCheckFail = false;
        }, 5000);
        this.countInterval = setInterval(() => {
          this.checkPayment();
        }, 5000);
      }
      this.buyerSigningLoading = false;
    }
  },
  watch: {
    paymentBoxOpen: function (newVal) {
      if (newVal) {
        this.initialise();
      }
    }
  }
};
</script>

<style scoped>
td {
  font-size: 14px;
}
</style>
