<template>
  <div class="dialogBox addToCartBox px-3 d-flex flex-column align-center">
    <message-box
      v-model="messageBox.state"
      :message-title="messageBox.title"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
    />
    <div class="d-flex flex-row-reverse" dark style="width: 100%; position: absolute; top: 0" v-if="!paid && !error">
      <v-btn icon width="30px" height="30px" class="ma-3" @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <p id="orderid-paypal" cols="12" md="8" style="display: none">{{ orderid }}</p>
    <v-progress-circular indeterminate v-if="!loaded" size="50" color="primary" />
    <div ref="paypal" style="width: 100%; max-width: 500px; height: 100%" class="my-5" v-if="!paid"></div>
    <div v-if="error">
      <v-alert type="error">{{ error }}</v-alert>
    </div>

    <!-- <iframe :src="paymentPage" width="100%" height="100%"> Pay </iframe> -->
  </div>
</template>

<script>
import PlatformLayout from "@/layouts/Platform";
import config from "@/config";
import { isEmpty } from "lodash/core";
import { payForService } from "../../services/orderService";
import MessageBox from "../../components/Common/MessageBox.vue";

export default {
  components: { MessageBox },
  name: "PremiumPayment",
  data: () => ({
    dealInfo: null,
    loaded: false,
    paid: false,
    paypalResult: {},
    error: false,
    messageBox: {
      state: false,
      title: "",
      content: "",
      cssClass: "error",
      destination: ""
    },
    messageClass: "message"
  }),
  props: {
    orderid: {
      type: String,
      required: false,
      default: ""
    },
    paymentInfo: {
      type: Object,
      required: true
    }
  },
  async mounted() {
    this.$emit(`update:layout`, PlatformLayout);
    this.$store.commit("showProgressLine", true);

    if (isEmpty(this.$store.state.userProfile)) {
      await this.$store.dispatch("loadUserProfile", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
    }
    let dealList = this.$store.state.dealList.buyer.concat(this.$store.state.dealList.seller);

    if (dealList.length === 0) {
      await this.$store.dispatch("getDealList", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
      await this.$store.dispatch("getDealList", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
    }
    this.dealInfo = this.$store.getters.getDealByOrderID(this.orderid);

    this.$store.commit("showProgressLine", false);
    try {
      const script = document.createElement("script");
      script.src = `${config.paypal_sdk}&locale=${this.locale}`;
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    } catch {
      (e) => e;
    }
  },
  computed: {
    userid() {
      return this.$store.state.userProfile.userid;
    },
    publicPath() {
      return process.env.BASE_URL;
    },
    locale() {
      let language;
      switch (this.$i18n.locale) {
        case "en":
          language = "en_HK";
          break;
        case "zhHant":
          language = "zh_HK";
          break;
        case "zhHans":
          language = "zh_CN";
          break;
        default:
          language = "en_HK";
      }
      return language;
    }
  },
  methods: {
    close() {
      this.$emit("close");
      if (this.paid) {
        const token = this.$session.get("token");
        const bazaarPromise = this.$store.dispatch("getBazaarList", {
          axios: this.$axios,
          config,
          token
        });
        const workPromise = this.$store.dispatch("getWorkList", {
          axios: this.$axios,
          config,
          token
        });
        const dealPromise = this.$store.dispatch("getDealList", {
          axios: this.$axios,
          config: config,
          token
        });
        Promise.all([bazaarPromise, workPromise, dealPromise]).then(() => {
          this.$store.commit("showProgressLine", false);
        });
      }
    },
    paymentSuccess() {
      this.$emit("paymentSuccess", { txnRef: this.paypalResult.paypalOrderid });
    },
    formatDate(date) {
      return new Date(date).toISOString().substr(0, 10);
    },
    setLoaded: function () {
      this.loaded = true;
      let self = this;

      window.paypal
        .Buttons({
          createOrder: async function () {
            // call the create and create order then returns the paypalOrderid
            const uri = `${config.backend_api}/payments/paypal/create`;
            // const uri = "http://localhost:8000/payments/paypal/create";
            let paymentInfo = {
              paymentType: `${self.paymentInfo.paymentType}`,
              paymentClass: `${self.paymentInfo.paymentClass}`,
              paymentDurationMonth: `${self.paymentInfo.paymentDurationMonth}`
            };
            const result = await fetch(uri, {
              method: "post",
              headers: {
                Authorization: self.$session.get("token"),
                "content-type": "application/json"
              },
              mode: "cors",
              body: JSON.stringify(paymentInfo)
            })
              .then((res) => res.json())
              .catch((e) => {
                self.messageBox = {
                  state: true,
                  title: "",
                  content: e.toString(),
                  cssClass: "error",
                  destination: ""
                };
              });

            localStorage.setItem("_paypalInfo", JSON.stringify(result.data));
            return result.data.paypalOrderid;
          },

          // data = {
          //   billingToken: null
          //   facilitatorAccessToken: "A21AAIDJ0G4prtCa_z-aT2A5aGubPvl15SlXPvduN_PayVxUfcrNiODjbBIIF-Af48dFOHA0VJQIz0XDCL1p3ANHoa7uMhRtA"
          //   orderID: "6XD15454CD541522D"
          //   payerID: "8S5RX6X5GJUGY"
          //   paymentID: null
          // }
          onApprove: async function (data) {
            // 1，call the capture api and capture the order

            const { payerID } = data;

            let paypalInfo = localStorage.getItem("_paypalInfo");

            if (!paypalInfo) {
              // prevent the user clear the cache, need to set up warning to inform users click the paypal button again

              return;
            }
            paypalInfo = JSON.parse(paypalInfo);
            paypalInfo = Object.assign(paypalInfo, { paypalPayerid: payerID });

            const uri = `${config.backend_api}/payments/paypal/capture`;
            // const uri = "http://localhost:8000/payments/paypal/capture";
            const result = await fetch(uri, {
              method: "post",
              headers: {
                Authorization: self.$session.get("token"),
                "content-type": "application/json"
              },
              mode: "cors",
              body: JSON.stringify(paypalInfo)
            }).then((res) => res.json());

            if (result.error) {
              self.error = result.error;
              return;
            }

            self.paypalResult = result;

            self.$emit("paymentComplete", { txnRef: paypalInfo.paypalOrderid });
            await self.payForService();
            localStorage.removeItem("_paypalInfo");
            // 2，Redirct to the details page
            // window.location.href="http//..."
          },
          style: {
            shape: "pill",
            label: "pay",
            color: "gold",
            height: 55
          }
        })
        .render(this.$refs.paypal);
    },
    async payForService() {
      const token = this.$session.get("token");
      let paypalInfo = JSON.parse(localStorage.getItem("_paypalInfo"));
      let paymentInfo = {
        paymentType: this.paymentInfo.paymentType,
        paymentClass: this.paymentInfo.paymentClass,
        paymentDurationMonth: this.paymentInfo.paymentDurationMonth,
        paypalOrderid: `${paypalInfo.paypalOrderid}`
      };

      const result = await payForService(this.$axios, config, token, paymentInfo);
      if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.cssClass = "message";
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else if (result.status !== 200) {
        this.error = `Payment cannot be completed. ${result.data.msg}`;
        return;
      }
      this.$session.set("token", result.data);
    },
    logout: function () {
      this.$store.dispatch("clearImageURL");
      this.$session.destroy();
      this.$router.push("/login").catch((e) => e);
      this.$store.commit("addr", null);
      this.$store.commit("changeState", false);
      this.$store.commit("refreshUserProfile", {});

      this.$store.dispatch("resetState");
    }
  }
};
</script>
