<template>
  <div class="addToCartBox px-6 py-2" style="overflow: hidden">
    <div style="width: 100%; display: flex; flex-direction: row-reverse">
      <v-btn icon width="30px" height="30px" class="ma-3" @click="close"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <div v-if="paymentDetails && price" style="display: flex; align-items: center; flex-direction: column">
      <h1 style="font-weight: 600; font-size: 24px">{{ $t("fpsAmount") }} {{ currency.concat(price) }}</h1>
    </div>
    <stripe-element-payment
      v-if="elementsOptions.clientSecret"
      ref="paymentRef"
      :pk="pk"
      :elements-options="elementsOptions"
      :confirm-params="confirmParams"
      :locale="locale"
      @loading="setPayLoading"
    />
    <v-skeleton-loader v-if="loading && !elementsOptions.clientSecret" type="card"></v-skeleton-loader>
    <div style="width: 100%; display: flex; align-items: center; flex-direction: column">
      <v-btn large color="primary" rounded depressed class="my-6" @click="pay" :loading="payLoading || !paymentDetails"
        >Pay Now</v-btn
      >
    </div>
  </div>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import config from "../../config";
import { createStripePayment, getStripePaymentIntent } from "../../services/orderService";
export default {
  components: {
    StripeElementPayment
  },
  data() {
    return {
      pk: config.stripe_pk,
      loading: true,
      payLoading: false,
      paymentDetails: null,
      elementsOptions: {
        appearance: {
          theme: "flat"
        }, // appearance options
        clientSecret: null
      },
      confirmParams: {
        return_url: null
      }
    };
  },
  props: {
    orderid: {
      type: String,
      required: false,
      default: ""
    },
    paymentInfo: {
      type: Object,
      required: true
    },
    paymentBoxOpen: {
      type: Boolean
    },
    dealInfo: {
      type: Object
    }
  },
  mounted() {
    this.setReturnURL();
    this.generatePaymentIntent();
  },
  methods: {
    async generatePaymentIntent() {
      this.loading = true;
      const token = this.$session.get("token");
      const payload = this.paymentInfo;
      const paymentIntentResponse = await createStripePayment(this.$axios, config, token, payload);
      if (paymentIntentResponse.status === 200) {
        this.elementsOptions.clientSecret = paymentIntentResponse.data.data;
        this.paymentDetails = await getStripePaymentIntent(
          this.$axios,
          config,
          token,
          paymentIntentResponse.data.data
        ).then((res) => {
          return res.data.data;
        });
        this.loading = false;
      }
    },
    setReturnURL() {
      localStorage.setItem("paymentInfo", JSON.stringify(this.paymentInfo));
      this.confirmParams.return_url = window.location.href;
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    close() {
      this.$emit("close");
    },
    setPayLoading(loadingState) {
      this.payLoading = loadingState;
    }
  },
  computed: {
    locale() {
      if (this.$i18n.locale === "zhHant") {
        return "zh-HK";
      } else if (this.$i18n.locale === "zhHans") {
        return "zh";
      } else {
        return "en";
      }
    },
    currency() {
      if (this.paymentDetails.currency) {
        return this.paymentDetails.currency.toUpperCase();
      } else {
        return "HKD";
      }
    },
    price() {
      if (this.loading || this.payLoading) {
        return null;
      }
      let amount;
      if (this.paymentDetails.amount) {
        amount = parseInt(this.paymentDetails.amount) / 100;
      } else {
        amount = parseInt(this.paymentDetails.price) / 100;
      }

      return amount.toFixed(2);
    }
  },
  watch: {
    paymentInfo: {
      handler() {
        this.loading = true;
        this.generatePaymentIntent();
      },
      deep: true
    }
  }
};
</script>
