export async function createPaymentFPS(axios, config, paymentInfo, token) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`
      }
    };
    const result = await axios.post(`${config.backend_api}/payments/fps/create`, paymentInfo, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}

export async function callbackFPS(axios, config, paymentInfo, token) {
  try {
    const opt = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
    const result = await axios.post(`${config.backend_api}/payments/fps/capture`, paymentInfo, opt);
    return result;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
}
